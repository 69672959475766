/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Subtitle, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"} ogImage={"https://cdn.swbpg.com/o/40398/0aebfaccac6e4ad7af3db8d7a5282a67.png"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-40sdkv --style4 --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 --center pl--0" anim={"6"} animS={"5"} style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1 fs--62 w--500 lh--1" content={"<span style=\"color: white\"><span style=\"font-style: italic;\">Servis plastových oken a dveří včetně eurooken<br>Montáž a servis žaluzií<br>Zaměření, instalace rolet a sítí proti hmyzu</span><br></span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--shape4 btn-box--cColor1 fs--22" href={"/kontakt-1#7w08e9ye4w8"} content={"Kontaktní formulář"} target={null} use={"page"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"uvod-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--10 pt--10" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span style=\"font-style: italic;\">Měření úniků tepla termovizí <br><span style=\"font-weight: bold;\">! ! ! ZDARMA ! ! !</span></span>"}>
              </Title>

              <Text className="text-box fs--26" style={{"maxWidth":""}} content={"<span style=\"font-style: italic;\">Na základě našeho měření zjistíme úniky tepla ve Vašem bytě - domě<br>&nbsp;navrhneme adekvátní řešení pro Váš lepší komfort a nemalou úsporu Vašich financí za vytápění</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-40sdkv pb--80 pt--80" name={"sluzby"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 pt--40" columns={"1"}>
            
            <ColumnWrapper className="pb--40 pt--40">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" name={"sluzby-2"} style={{"backgroundColor":"#0c2538"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1 fs--62" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant); font-style: italic;\">Naše služby:</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant)\">Revize a servis plastových oken a eurooken</span>"}>
              </Subtitle>

              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">— Obytné prostory, byty, domy<br>— Kancelářské prostory<br>— Prodejny<br>— Provozovny<br>— Hotely, penziony a další&nbsp;<br></span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant)\">Žaluzie, sítě do oken, rolety&nbsp;</span>"}>
              </Subtitle>

              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-blend--95);\">— Kancelářské prostory<br>— Obytné prostory<br>— Prodejny<br>— Provozovny<br>— Hotely, penziony a další&nbsp;</span><span style=\"color: var(--color-dominant)\"><br></span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant)\">Dveře značky Sapeli&nbsp;</span>"}>
              </Subtitle>

              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">— Bezpečnostní&nbsp;<br>— Vchodové do bytu<br>— Interiérové<br>— Posuvné<br>— Bezpečnostní&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"reference"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"font-style: italic;\">Náhledy z termokamery&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--3 mt--30" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/40398/c30da4803b104052af3ba4f53b840fc3.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""}>
              </Image>

              <Text className="text-box mt--10" content={"<span style=\"font-style: italic;\">Modrá značí průnik zimy k vám domů</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/40398/371e5f700d9e465ea61068217f98b054.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""}>
              </Image>

              <Text className="text-box mt--10" content={"<span style=\"font-style: italic;\">Modrá značí průnik zimy k vám domů</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/40398/c5fa4dbeff214a3ea193f9c6a9eea7a1.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""}>
              </Image>

              <Text className="text-box mt--10" content={"<span style=\"font-style: italic;\">Modrá značí průnik zimy k vám domů</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-40sdkv --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/68667981f96f43c88fd25ab739f91867_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--20" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" content={"<span style=\"color: white\">+420 494 909 023<br>fit-okna@seznam.cz<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}